export const CHECKOUT_ERRORS = {
    "checkData": "Bitte überprüfen Sie ihre persönlichen Daten.",
    "quantity": "Die gewählte Artikelanzahl überschreitet die maximale Bestellmenge. Die Anzahl wurde automatisch angepasst.",
    "other": "Es ist ein Fehler aufgetreten.",
    "login": "Die Kombination aus E-Mail-Adresse und Passwort ist fehlerhaft.",
    "providerValidation": "Bei der Bezahlung ist ein Fehler aufgetreten. Bitte wählen Sie einen anderen Zahlungsanbieter.",
    "changedItemsValidation": "Die Artikel im Warenkorb wurden geändert.",
    "klarnaDefaultError": "Zahlungsart wurde abgelehnt. Bitte wählen Sie eine andere Zahlungsart.",
    "klarnaInvoiceRejected": "Klarna Rechnungskauf wurde abgelehnt. Bitte wählen Sie eine andere Zahlungsart.",
    "klarnaInstallmentRejected": "Klarna Ratenzahlung wurde abgelehnt. Bitte wählen Sie eine andere Zahlungsart.",
    "klarnaSofort2Rejected": "Klarna Sofort wurde abgelehnt. Bitte wählen Sie eine andere Zahlungsart.",
    "user-service::30": "Bitte geben Sie eine Telefonnummer an.",
    "user-service::33": "Das Passwort ist unsicher.",
    "QuantityCartRule::9": "Die gewählte Artikelanzahl überschreitet die maximale Bestellmenge.",
    "user-service::35": "Bitte kontrollieren Sie Ihre Eingabe der Straße.",
    "checkout::12": "Aufgrund des abweichenden Empfängers ist die Auswahl des Rechnungskaufes nicht möglich.<br />Bitte ändern Sie den Namen des Empfängers auf die Daten der Rechnungsadresse oder wählen Sie eine andere Zahlungsart.",
    "user-service::34": "Bitte kontrollieren Sie Ihre Eingabe der Stadt.",
    "user-service::37": "Bitte geben Sie eine Hausnummer ein.",
    "checkout::10": "Validierungsfehler",
    "user-service::36": "Bitte kontrollieren Sie Ihre Eingabe der Postleitzahl.",
    "user-service::39": "Bitte kontrollieren Sie Ihre Eingabe der Telefonnummer.",
    "user-service::38": "Bitte kontrollieren Sie Ihre Eingabe der Hausnummer.",
    "payment-service::120": "User canceled the payment request",
    "user-service::610": "Basic credentials not found",
    "user-service::730": "Refresh authentication token invalid",
    "user-service::411": "Institution mapping not found",
    "payment-service::400": "Unknown payment provider",
    "user-service::410": "Customer nature not found",
    "QuantityCartRule::1": "Jeder Artikel darf maximal dreimal gleichzeitig bestellt werden.",
    "deprecated::401": "Ihre Session ist abgelaufen. Bitte laden Sie die Seite neu.",
    "user-service::42": "Ausgeschlossen: Postfachanschriften und Packstationen.",
    "deprecated::402": "Ihre Session ist abgelaufen. Bitte laden Sie die Seite neu.",
    "user-service::41": "Ihre ausgewählte Postleitzahl ist außerhalb unseres Liefergebiets",
    "user-service::44": "Die maximale Zeichenzahl für das Feld Vorname beträgt 50 Zeichen",
    "user-service::46": "Die maximale Zeichenzahl für das Feld Straße beträgt 50 Zeichen",
    "user-service::45": "Die maximale Zeichenzahl für das Feld Nachname beträgt 50 Zeichen",
    "deprecated::403": "Access Denied",
    "user-service::48": "Die maximale Zeichenzahl für das Feld Firma beträgt 50 Zeichen",
    "deprecated::404": "Payload missing",
    "user-service::47": "Die maximale Zeichenzahl für das Feld Stadt beträgt 50 Zeichen",
    "user-service::49": "Die maximale Zeichenzahl für das Feld Hausnummer beträgt 5 Zeichen",
    "payment-service::140": "There was an error authenticating the request",
    "payment-service::130": "The payment provider declined the payment",
    "order-service::1": "Order client id is not set",
    "order-service::3": "Bitte fügen Sie Artikel zu ihrem Warenkorb hinzu.",
    "order-service::2": "Der Bestellung ist kein Kunde zugeordnet.",
    "order-service::5": "Bitte eine Bezahlart wählen.",
    "user-service::620": "Reset password token expired",
    "order-service::4": "Die Bestellung hat nicht den korrekten Status.",
    "user-service::421": "Duplicate wishlist",
    "user-service::300": "Invalid payload",
    "checkout::4": "Die Bestell-Id ist veraltet. Bitte neu laden.",
    "user-service::640": "Reset password token not found",
    "order-service::7": "Ein Artikelpreis wurde geändert.",
    "order-service::6": "Bestellung konnte nicht erstellt werden.",
    "checkout::5": "Unbekannte Versandoption",
    "checkout::2": "Kundendaten sind nicht gesetzt.",
    "user-service::440": "Remove default user profile",
    "checkout::3": "Die Bestell-Id ist veraltet. Bitte neu laden.",
    "deprecated::1404": "Entity not found",
    "order-service::8": "Der Artikel ist nicht verfügbar.",
    "checkout::1": "Bitte akzeptieren Sie die AGB.",
    "payment-service::160": "Die express bezahlung ist abgelaufen. Klicken sie <a href='?step=1' class='text-primary'>hier</a> um den vorgang neu zu starten.",
    "payment-service::150": "The payment was already processed",
    "payment-service::100": "Invalid payment request",
    "user-service::910": "Multiple credential validation errors",
    "checkout::8": "Bitte geben Sie Ihre Rechnungsadresse ein.",
    "checkout::9": "Bitte geben Sie eine Lieferadresse ein.",
    "checkout::6": "Artikelkombination bzgl. des Versands ungültig. Bitte schließen Sie den Bestellvorgang einzeln ab.",
    "checkout::7": "Bestellungsfehler.",
    "user-service::630": "Reset password token",
    "deprecated::500": "Internal Server Error",
    "user-service::22": "Bitte geben Sie Ihren Vornamen ein.",
    "user-service::21": "Bitte geben Sie eine Stadt ein.",
    "deprecated::6": "Invocation target",
    "user-service::24": "Bitte geben Sie eine Straße ein.",
    "user-service::23": "Bitte geben Sie einen Nachnamen ein.",
    "deprecated::502": "Bad Gateway",
    "deprecated::4401": "Ungültige zugangsdaten.",
    "user-service::26": "Bitte eine Anrede auswählen.",
    "deprecated::4402": "Der benutzer existiert bereits.",
    "user-service::25": "Bitte eine PLZ eingeben.",
    "user-service::28": "Bitte ein Land auswählen.",
    "user-service::27": "Bitte füllen Sie Ihre adresse aus.",
    "user-service::29": "E-Mail Adresse ungültig.",
    "shipping-service::2": "Der Preis der Versandart konnte nicht bestimmt werden.",
    "order-service::12": "Die Versandkosten wurden geändert.",
    "shipping-service::3": "Eine Versandart ist nicht ausgewählt.",
    "shipping-service::4": "Eine Versandart ist nicht ausgewählt.",
    "shipping-service::5": "Eine Versandart ist nicht ausgewählt.",
    "payment-service::110": "Fehler beim Zahlungsanbieter",
    "user-service::920": "Ungültige zugangsdaten.",
    "user-service::403": "Der Adress fehlt eine eindeutige Id.",
    "user-service::402": "Ihre Adresse konnte nicht gefunden werden.",
    "user-service::720": "Bitte die Seite neu laden und erneut versuchen.",
    "user-service::444": "Benuterprofiel konnte nicht gefunden werden.",
    "user-service::202": "Die angegebene E-Mail adresse ist ungültig.",
    "user-service::400": "Falscher Benutzername oder Passwort.",
    "shipping-service::1": "Versandoptionen können nicht berechnet werden.",
    "user-service::201": "Die angegebene E-Mail ist bereits vergeben.",
    "user-service::409": "Duplicate customer nature"
  }